// Override (simple)
// --------
//
//  Table of Contents:
//
//	1. Body
//	2. Content
//		2.1 Buttons
//		2.? Callouts
//		2.3	Content Management page
//		2.3	Forms
//		2.5 full.width
//		2.6 Home page
//			2.6.1	bizazz
//			2.6.2	.middle
//			2.6.3	.wegrow, inc .wombat and .grass
//		2.7 Portfolio page
//		2.8 Tables
//		2.9 Text Styles
//	3. Fonts
//	4. Banner
//	5. Menu
//	6. Footer
//	7. Menus, inc sticky, top amd title bar
//
//	---- end table of contents ---

//	1. Body
// -----------------------------------------------------------------------1
body {
	font-family: "Open Sans", sans-serif;
}

//	2. Content
// -----------------------------------------------------------------------2

.content {
	.callout {
		z-index: -1;
	}
	.card {
		z-index: -1;
	}
	.oldprice {
		text-decoration: line-through;
		color: #b0b0b0;
		margin-right: 7px;
	}
	.incgst {
		font-size: 80%;
	}
	.shoplink {
		padding: 10px 0 20px 0;
	}
	h3 {
		color: #080;
	}
}

//	4. Banner
// -----------------------------------------------------------------------2

#header {
	.logo {
		height: 100%;
		margin-right: 1rem;
		padding: 1rem 0;
	}
	h1 {
		margin-top:0.5rem;
		margin-bottom: 0;
	}
	.contact {
		padding-top: 0.5rem;
		* {
			margin-bottom: 0;
		}
	}
}
.search-container {
	input {
		display: inline-block;
		width: auto;
		margin: 0;
	}
	float: right;
	button {
		float: right;
		cursor: pointer;
		font-size: 20px;
		padding: 7px;
		border: 1px solid #ccc;
		background-color: #fc0;
	}
	button:hover {
		background-color: #da0;
	}
}

//	5. Menu
// -----------------------------------------------------------------------2

#menu {
	.title-bar {
		.title-bar-left {
			font-size: 20px;
			a {
				margin-right: 0.5rem;
				img {
					height: 34px;
					margin-top: 2px;
					margin-bottom: 2px;
				}
			}
		}
	}
	.top-bar {
		.menu-text {
			margin-right: 2rem;
			font-size: 20px;
			color: #fff;
			@include breakpoint(small only) {
				display: none;
			}
			a {
				display: inline-block;
				margin-right: 10px;
				img {
					height: 34px;
					margin-top: 2px;
				}
			}
		}
	}
}

//	6. Footer
// -----------------------------------------------------------------------2

#footer {
	background-color: #888;
	color: #fff;
	padding-top: 1rem;
	a {
		color: #9cd7ff;
	}
}
