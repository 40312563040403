// Bizazz (simple)
// ------
//
//  Table of Contents:
//
//	a. Variables
//	1. Accordion
//  2. Badge, Label
//	3. Blockquotes
//	4. Buttons
//	5. Callout
//	6. Cards
//	7. Clearfloats
//	8. Content
//	9. div.section
//	10. Error
//	11. Figure
//	12. Icons / quotes
//	13. Image
//	14. Light Box
//	15. Lists
//	16. no-js breakpoints
//	17. seolink
//	18. Tabs
//	19. Text
//	20. Tooltip
//  21. Grid
//  22. Orbit
//
//	---- end table of contents ---

//	a.	Variables
// ---------------------------------------------------------------------------a

// below moved to bizazzsettings.scss
// $global-radius: 0.6rem;

//	1.	Accordion
// --------------------------------------------------------------------------1

.accordion:not(#bkaccordion) {
	box-shadow: none;
	:last-child:not(.is-active) > .accordion-title {
		border-bottom: 1px solid #808080;
	}
	.accordion-item:first-child > :first-child {
		border-radius: $accordionRadius $accordionRadius 0 0 !important;
	}
	:last-child:not(.is-active) > .accordion-title {
		border-radius: 0 0 $accordionRadius $accordionRadius;
	}
	.accordion-item:last-child > :last-child {
		border-radius: 0 0 $accordionRadius $accordionRadius;
	}
	ul, ol {
		margin-bottom: 1rem
	}

	@if variable-exists(accordionRadiusOption) {
		&.radius {
			.accordion-item:first-child > :first-child {
				border-radius: $accordionRadiusOption $accordionRadiusOption 0 0 !important;
			}
			:last-child:not(.is-active) > .accordion-title {
				border-radius: 0 0 $accordionRadiusOption $accordionRadiusOption;
			}
			.accordion-item:last-child > :last-child {
				border-radius: 0 0 $accordionRadiusOption $accordionRadiusOption;
			}
		}
	}
	@if variable-exists(accordionShadowOption) {
		&.shadow {
			-webkit-box-shadow: $accordionShadowOption;
			box-shadow: $accordionShadowOption;
		}
	}
	.accordion-title {
		font-size: 1rem;
		padding: 10px 45px 10px 10px;
		border: 1px solid #808080;
		margin-top: 5px;
		color: $accordionColorDefault;
		background-color: $accordionBackgroundDefault; // without this line visited accordions show darkgrey on mouse out till next mouse event
		&:hover {
			background-color: $accordionHoverBackgroundDefault;
			color: scale-color($accordionColorDefault, $lightness: +32%);
			&:before {
				border-color: scale-color($accordionColorDefault, $lightness: +32%);
			}
		}
		&:focus {
			outline: #808080;
			:not(:hover) {
				color: $accordionColorDefault;
			}
		}
		@media screen and (max-width: 26em) {
				font-size: 0.8rem;
		}
		p,h1,h2,h3,h4,h5,h6 {
			margin-top: 0;
			margin-bottom: 0;
		}

		@each $name, $value in $colours {
			&.#{map-get($value, name)} {
				color: map-get($value, text);
				&:hover {
					color: map-get($value, text);
					background-color: map-get($value, lighter);
					&:before {
						border-color: map-get($value, text);
					}
				}
				&:focus :not(:hover) {
					color: map-get($value, text);
				}
				&:before {
					border: solid map-get($value, text);
					border-width: 0 3px 3px 0;
				}
			}
		}
	}

	.accordion-title:before {
		content: '';
		border: solid $accordionColorDefault;
		border-top-width: medium;
		border-right-width: medium;
		border-bottom-width: medium;
		border-left-width: medium;
		border-width: 0 3px 3px 0;
		display: inline-block;
		padding: 6px;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		position: absolute;
		right: 2.25rem;
		top: 45%;
		@media screen and (max-width: 26em) {
			right: 1rem;
		}
	}
	.is-active > .accordion-title::before {
		content: '';
		-webkit-transform: rotate(-135deg);
		transform: rotate(-135deg);
		top: 55%;
	}

	.accordion-content {
		border: 1px solid #e6e6e6;
		margin-bottom: 5px;
		// padding: 0;
	}
}
.bizTabs.accordion {
	@each $name, $value in $colours {
		&.#{map-get($value, name)} {
			.accordion-title {
				color: map-get($value, text);
			}

			.accordion-title:before {
				border: solid map-get($value, text);
				border-width: 0 3px 3px 0;
			}
		}
	}
}

//	2.	Badge, Label
// --------------------------------------------------------------------------2
.badge, .label {
	@each $name, $value in $colours {
		@if not (map-has-key($value, foundation)) {
			&.#{map-get($value, name)} {
				background-color: map-get($value, text);
				//color: map-get($value, contrastColour);
				color: #fff;
			}
		}
	}
}

//	3.	Blockquotes
// --------------------------------------------------------------------------3

blockquote {
	margin: 0 0 1rem 1rem;
	padding: 0 1.25rem 0 1.1875rem;
	cite {
		font-size: inherit;
	}
	&.blockquoteBorderHide {
		border-left: none;
	}
}

//	4.	Buttons
//	-------------------------------------------------------------------------4

.button-radius {
	border-radius: $global-radius;
}
.button.round {
	border-radius: 35px;
}
.button.oval {
	border-radius: 50%;
}
.no-radius {
	border-radius: 0px;
}
.button {
	@each $name, $value in $colours {
		@if not (map-has-key($value, foundation)) {
			&.#{map-get($value, name)} {
				background-color: map-get($value, text);
				color: map-get($value, contrast);
			}
		}
	}
}

//	5.	Callouts
//	-------------------------------------------------------------------------5

.callout {
	position: relative;
	box-shadow: none;
	& > :first-child {
		margin-top: 1rem;
	}
	border-radius: $calloutRadius $calloutRadius $calloutRadius $calloutRadius !important;
	//border-color: $tabsColorDefault;
	@if variable-exists(calloutRadiusOption) {
		&.radius {
			border-radius: $calloutRadiusOption $calloutRadiusOption $calloutRadiusOption $calloutRadiusOption !important;
		}
	}
	@if variable-exists(calloutShadowOption) {
		&.shadow {
			-webkit-box-shadow: $calloutShadowOption;
			box-shadow: $calloutShadowOption;
		}
	}

	@each $name, $value in $colours {
		&.#{map-get($value, name)} {
			border-color: map-get($value, light);
			color: map-get($value, contrast);
			background-color: map-get($value, light);
		}
	}
	&.full {
		margin-top: 0;
		margin-bottom: 0;
		@include breakpoint(small) {
			margin-left: - map-get($grid-margin-gutters, small)/2;
			margin-right: - map-get($grid-margin-gutters, small)/2;
			padding-left: map-get($grid-margin-gutters, small)/2;
			padding-right: map-get($grid-margin-gutters, small)/2;
		}
		@include breakpoint(medium) {
			margin-left: - map-get($grid-margin-gutters, medium)/2;
			margin-right: - map-get($grid-margin-gutters, medium)/2;
			padding-left: map-get($grid-margin-gutters, medium)/2;
			padding-right: map-get($grid-margin-gutters, medium)/2;
		}
	}
}

//.callout-radius {
//		border-radius: $global-radius;
//}

//	6.	Cards
//	-------------------------------------------------------------------------6

.card {
	position: relative;
	box-shadow: none;
	border-radius: $cardRadius $cardRadius $cardRadius $cardRadius !important;
	//border-color: $tabsColorDefault;
	@if variable-exists(cardRadiusOption) {
		&.radius {
			border-radius: $cardRadiusOption $cardRadiusOption $cardRadiusOption $cardRadiusOption !important;
		}
	}
	@if variable-exists(cardShadowOption) {
		&.shadow {
			-webkit-box-shadow: $cardShadowOption;
			box-shadow: $cardShadowOption;
		}
	}
	.card-divider {
	  display: block;
		background-color: $tabsColorDefault;
		color: $tabsBackgroundDefault;
	}
	.card-section {
		background-color: #fff;
	}

	@each $name, $value in $colours {
		&.#{map-get($value, name)} {
			border-color: map-get($value, text);
			  .card-divider {
				  color: map-get($value, contrast);
				  background-color: map-get($value, light);
			  }
			  .card-section {
				  background-color: map-get($value, lighter);
			  }
		}
	}

	//@each $name, $value in $colours {
	//	&.#{map-get($value, name)} {
	//		border-color: map-get($value, color);
	//	}
	//}

	.card-label {
		border-style: solid;
		float:right;
		height: 0px;
		width: 0px;
		position: absolute;
		top: 0;
		right: 0;
		border-width: 0 rem-calc(70) rem-calc(40) 0;
		border-color: rgba(255,255,255,0) $black rgba(255,255,255,0) rgba(255,255,255,0);
		.card-label-text {
			color: $white;
			font-size: 0.9rem;
			font-weight: bold;
			position: relative;
			right: rem-calc(-35);
			top: -3px;
			white-space: nowrap;
			transform: rotate(30deg);
		}
		@each $name, $value in $colours {
			&.#{map-get($value, name)} {
				border-color: rgba(255,255,255,0) map-get($value, text) rgba(255,255,255,0) rgba(255,255,255,0);
				.card-label-text {
					//color: map-get($value, contrast);
					color: #fff;
				}
			}
		}
	}
}

//	7.	Clearfloats
//	-------------------------------------------------------------------------7

.clearfloats {
	clear: both;
	//padding-top: 30px;
}
.clearright {
	clear: right;
}
.clearleft {
	clear: left;
}

//	8.	Content
//	-------------------------------------------------------------------------8

.padding30 {
	padding: 30px;
}
.paddingTB30 {
	padding-top: 30px;
	padding-bottom: 30px;
}
.marginTop30 {
	margin-top: 30px;
}

.content {
	a[name] {
		color: $black;
		cursor: default;
	}
	a:not(.button):not([name]):not(.accordion-title) {
		text-decoration: underline;
	}
	li>p {
		margin: 1rem 0 0 0;
	}
	li>p:first-child {
		margin: 0;
	}
	:is(h1, h2, h3, h4, h5, h6) {
		margin-top: 3rem;
	}
	@include breakpoint(small only) {
		:is(h1, h2, h3, h4, h5, h6) {
			margin-top: 1.5rem;
		}
	}
	:is(h1, h2, h3, h4, h5, h6) + :is(h1, h2, h3, h4, h5, h6) {
		margin-top: 0;
	}

	.card, .callout {
		:is(h1, h2, h3, h4, h5, h6) {
			margin-top: 0;
		}
	}
}
.titlelabel {
	color: $form-label-colour;
}

#footer {
	a[name] {
		color: $black;
		cursor: default;
	}
	a:not(.button):not([name]):not(.accordion-title) {
		text-decoration: underline;
	}
}

//	9.	div.section
//	-------------------------------------------------------------------------9

div.section {
	padding: 1rem 0;
}

//	10.	Error
//	-------------------------------------------------------------------------10

.error {
	font-weight: bold;
	color: #ff0000;
}
#form-error {
	color: #ff0000;
	font-weight: bold;
}

//	11.	Figure
//	-------------------------------------------------------------------------11

figure {
	margin: 0;
	@media only screen and (max-width: 40em) {
		display: block;
		margin: 0 auto 5px auto;
		max-width: 100%;
		height: auto;
	}
}
figure.captionovertop, figure.captionoverbottom {
	position: relative;
}
figcaption {
	width: 100%;
	text-align: center;
}
figure.captionovertop figcaption {
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: rgba(51, 51, 51, 0.8);
	color: #ffffff;
}
figure.captionoverbottom figcaption {
	position: absolute;
	bottom: 0px;
	left: 0px;
	background-color: rgba(51, 51, 51, 0.8);
	color: #ffffff;
	padding: rem-calc(3 0);
}
figure.imgcentre {
  display: table;
  margin: 0 auto;
}


//	12.	Icons / quotes
// --------------------------------------------------------------------------12

.left-arrow:after {
	font-family: "foundation-icons";
	content: " \f10a";
}
.right-arrow:after {
	font-family: "foundation-icons";
	content: " \f10b";
}
.telephone-icon:after {
	font-family: "foundation-icons";
	content: " \f1f8";
}
.mail-icon:after {
	font-family: "foundation-icons";
	content: " \f16d";
}
.quotes {
	&:before {
		content: "\201C";
	}
	&:after {
		content: "\201D";
	}
}
.quotes-single {
	&:before {
		content: "\2018";
	}
	&:after {
		content: "\2019";
	}
}
.quotes-bold {
	&:before {
		content: "\275D";
	}
	&:after {
		content: "\275E";
	}
}
.quotes-french {
	&:before {
		content: "\00AB";
	}
	&:after {
		content: "\00BB";
	}
}
.hide-blockquote-border {
	border-left: none;
}

//	13.	Image
//	-------------------------------------------------------------------------13

.imgborder {
	border: solid 1px #333333;
}
.imgborderwhite {
	background: #ffffff;
	padding: 5px;
	border: solid 1px #e0e0e0;
}
.imgleft {
	float: left;
	margin: 0 10px 5px 0;
	@media only screen and (max-width: 40em) {
		float: none;
		display: block;
		margin: 0 auto 5px auto;
		text-align: center;
	}
}
.imgcentre {
	margin: 0 auto 0 auto;
}
.imgright {
	float: right;
	margin: 0 0 5px 10px;
	@media only screen and (max-width: 40em) {
		float: none;
		display: block;
		margin: 0 auto 5px auto;
		text-align: center;
	}
}
.img-shadow, .show-shadow {
	border: solid 1px #888;
	-moz-box-shadow: 4px 4px 6px 0px #888;
	-webkit-box-shadow: 4px 4px 6px 0px #888;
	box-shadow: 4px 4px 6px 0px #888;
}
.img-radius {
	border-radius: $global-radius;
}
.img150px {
	width: 150px;
}

//	14.	Light Box
//	------------------------------------------------------------------------14

.clearing {
	.thumbnail {
		margin: 0 10px 10px 0;
	}
}
#overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #000000 url(/assets/img/spinner.gif) no-repeat scroll center center;
}
#lightbox {
	position: absolute;
	z-index: 99;
	max-width: 100%;
	border: solid 5px #ffffff;
	img {
		display: block;
	}
}

//	15. Lists
//	--------------------------------------------------------------------------15
ul.disc {
	list-style-type: disc;
}
ul.circle {
	list-style-type: circle;
}
ul.square {
	list-style-type: square;
}
ul.grid-x {
	list-style-type: none;
}
ol li {
    position: relative;
}
@include breakpoint(small only) {
	ol, ul {
		margin-left: $list-side-margin-small;
		ol, ul {
			margin-left: $list-nested-side-margin-small;
		}
	}
}


//	16.	no-js breakpoints
//	--------------------------------------------------------------------------16

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }
  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
    #header {
    	height: 68px;
    	.top-bar {
    		display: none;
    	}
    }
  }
}

//	17.	seolink
//	-------------------------------------------------------------------------17

a.seolink:link, a.seolink:visited, a.seolink:hover {
	color: #000000;
	text-decoration: none;
}

//	18.	Tabs
//	-------------------------------------------------------------------------18
.tabs {
	// was dl.tabs
	margin-left: 5px;
	border: 0;
	.tabs-title {
		// was dd
		margin-left: 5px;
		a {
			border-radius: 10px 10px $tabsRadius $tabsRadius;
			font-size: 1rem;
			padding: 0.825rem 1.25rem;
			text-decoration: none !important;
			// outline: none;
		}
		@each $name, $value in $colours {
			&.#{map-get($value, name)} {
				> a {
					//color: map-get($value, contrast);
					color: #fff;
					background-color: map-get($value, text);
					&:hover {
						color: map-get($value, contrast);
						background-color: map-get($value, light);
					}
				}
				> a[aria-selected='true'] {
					background-color: map-get($value, text);
				}
			}
		}
	}
}
.tabs-content {
	border: none;
	margin-bottom: 1rem;
	:is(h1, h2, h3, h4, h5, h6) {
		margin-top: 0;
	}
	&.shadow .tabs-panel {
		-webkit-box-shadow: $tabsShadowOption;
		box-shadow: $tabsShadowOption;
	}
	.tabs-panel {
		padding: 10px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		@each $name, $value in $colours {
			&.#{map-get($value, name)} {
				border-top: 10px solid map-get($value, text);
				border-left: 1px solid  map-get($value, text);
				border-right: 1px solid  map-get($value, text);
				border-bottom: 1px solid  map-get($value, text);
			}
		}
	}
	@if variable-exists(tabsRadiusOption) {
		&.radius .tabs-panel {
			border-bottom-left-radius: $tabsRadiusOption;
			border-bottom-right-radius: $tabsRadiusOption;
		}
	}
}

//	19.	Text
//	-------------------------------------------------------------------------19

@each $name, $value in $colours {
	.#{map-get($value, name)}-text-colour {
		color: map-get($value, text) !important;
	}
	//.content .#{map-get($value, name)}-text-colour {
	//	color: map-get($value, text) ;
	//}
}
.highlight-text-colour {
	background: #ffff00;
}
.normal {
	font-weight: normal;
}

//	20.	Tooltip
// --------------------------------------------------------------------------20

.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 150%;
	left: 50%;
	margin-left: -60px;
}
.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}
.tooltip:hover .tooltiptext {
	visibility: visible;
}

/*
*  responsive tooltip
*/
#tooltip {
	text-align: left;
	color: #fff;
	background: #111;
	position: absolute;
	z-index: 100;
	padding: 15px;
	font-size: 1rem;
}
#tooltip:after /* triangle decoration */ {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #111;
	content: '';
	position: absolute;
	left: 50%;
	bottom: -10px;
	margin-left: -10px;
}
#tooltip.top:after {
	/*border-top-color: transparent;*/
	border-top: none;
	border-bottom: 10px solid #111;
	/*top: -20px;*/
	top: -10px;
	bottom: auto;
}
#tooltip.left:after {
	left: 10px;
	margin: 0;
}
#tooltip.right:after {
	right: 10px;
	left: auto;
	margin: 0;
}
#tooltip sup {
	font-size: 70%;
	padding-right: 3px;
}

//  21. Grid
// --------------------------------------------------------------------------21

.grid-container .grid-container {
	padding-left: 0;
	padding-right: 0;
}
@if variable-exists(containers) {
	@each $name, $value in $containers {
		.#{map-get($value, name)}-container {
			color: map-get($value, color);
			background-color: map-get($value, background-color);
			box-shadow: 0 0 0 100vmax map-get($value, background-color);
			clip-path: inset(0 -100vmax);
		}
	}
}

//  22. Orbit
// --------------------------------------------------------------------------21

.orbit {
	&.full {
		@include breakpoint(small) {
			margin-left: - map-get($grid-margin-gutters, small)/2;
			margin-right: - map-get($grid-margin-gutters, small)/2;
		}
		@include breakpoint(medium) {
			margin-left: - map-get($grid-margin-gutters, medium)/2;
			margin-right: - map-get($grid-margin-gutters, medium)/2;
		}
	}
}
.orbit-caption {
	padding: 0.5rem;
}
