//	bizazz-topbar (simple)
//	----------------------------------

.title-bar {
	position: relative; // allow menu to slide out from under the title bar
	z-index: 2;
	padding: $titlebar-padding;
	.title-bar-title { // site title small screen
		color: $titlebar-title-color;
		text-decoration: $topmenu-text-decoration;
		&:hover {
			color: $titlebar-title-hover;
		}
	}
}
#menu {
	background-color: $topbar-background;
}
.top-bar {
	position: relative; // allow menu to slide out from under the title bar
	z-index: 1;
	padding: 0;
	ul {
		background: transparent;
	}
	.menu .menu-text a { // site title
		padding: 0;
		color : $topbar-title-color;
		&:hover {
			color: $topbar-title-hover;
			//background: $topbar-background;
			background: transparent;
		}
	}
	.dropdown.menu > li {
		&.menu-text {
			padding: $topmenu-title-padding;
			& > a {
				color: $topbar-title-color;
				&:hover {
					color: $topbar-title-hover;
					background: transparent;
				}
			}
		}
		& > a {
			color: $topbar-colour;
			&:hover {
				color: $topbar-colour-hover;
				background: $topbar-background-hover;
				text-decoration: $topmenu-text-decoration;
			}
		}
		&.current > a {
			color: $topbar-colour-current;
			background: $topbar-background-current;
			text-decoration: $topmenu-text-decoration;
			//outline: 1px solid $topbar-colour-current;
			&:hover {
				color: $topbar-colour-hover;
				background: $topbar-background-hover;
				text-decoration: $topmenu-text-decoration;
			}
		}
		&.is-active > a {
			color: $topbar-colour-hover;
			background: $topbar-background-hover;
		}
	}

	.dropdown .is-dropdown-submenu {
		border: none;
		li {
			a {
				color : $topbar-submenu-colour;
				background: $topbar-submenu-background;
				&:hover {
					color: $topbar-submenu-colour-hover;
					background: $topbar-submenu-background-hover;
					text-decoration: $topmenu-text-decoration;
				}
			}
			&.is-active > a {
				color: $topbar-submenu-colour-hover;
				background: $topbar-submenu-background-hover;
			}
			&.current > a {
				color: $topbar-submenu-current-colour;
				background: $topbar-submenu-current-background;
				text-decoration: $topmenu-text-decoration;
				&:hover {
					color: $topbar-colour-hover;
					background: $topbar-background-hover;
				}
			}
			&.current.is-active > a {
				color: $topbar-submenu-colour-hover;
				background: $topbar-submenu-background-hover;
			}
		}
	}
	// top menu arrow hover
	.dropdown.menu.medium-horizontal, .dropdown.menu.large-horizontal {
		> li.is-dropdown-submenu-parent {
			& > a::after {
				border-color: $topbar-colour transparent transparent;
			}
			& > a:hover::after {
				border-color: $topbar-colour-hover transparent transparent;
			}
			&.is-active > a::after {
				border-color: $topbar-submenu-colour-hover transparent transparent;
			}
			&.current > a::after {
				border-color: $topbar-colour-current transparent transparent;
			}
			&.current.is-active > a::after {
				border-color: $topbar-submenu-colour-hover transparent transparent !important;
			}
		}
	}
	// submenu arrow standard, hover and current
	.is-dropdown-submenu {
		> li.is-dropdown-submenu-parent {
			&.opens-right {
				& > a::after {
					border-color: transparent transparent transparent $topbar-submenu-colour;
				}
				& > a:hover::after {
					border-color: transparent transparent transparent $topbar-submenu-colour-hover;
				}
				&.is-active > a::after {
					border-color: transparent transparent transparent $topbar-submenu-colour-hover;
				}
				&.current > a::after {
					border-color: transparent transparent transparent $topbar-submenu-current-colour;
				}
				&.current.is-active > a::after {
					border-color: transparent transparent transparent $topbar-submenu-colour-hover;
				}
			}
			&.opens-left {
				& > a::after {
					border-color: transparent $topbar-submenu-colour transparent transparent;
				}
				& > a:hover::after {
					border-color: transparent $topbar-submenu-colour-hover transparent transparent;
				}
				&.is-active > a::after {
					border-color: transparent $topbar-submenu-colour-hover transparent transparent;
				}
				&.current > a::after {
					border-color: transparent $topbar-submenu-current-colour transparent transparent;
				}
			}
		}
	}



	.is-drilldown { // stops drilldown narrowing
		width: 100%;
		max-width: 100%;
	}
	.drilldown li {
		a {
			color: $drilldown-colour;
			background: $drilldown-background;
			&:hover {
				color: $drilldown-colour-hover;
				background: $drilldown-background-hover;
			}
		}
		&.current > a {
			color: $drilldown-current-colour;
			background: $drilldown-current-background;
			&:hover {
				color: $drilldown-colour-hover;
				background: $drilldown-background-hover;
			}
		}
	}
	.drilldown {
		.is-drilldown-submenu {
			background: $drilldown-background;
		}
		.is-drilldown-submenu-parent {
			// arrow right color
			a::after {
				border-color: transparent transparent transparent $drilldown-colour;
			}
			a:hover::after {
				// arrow right hover color
				border-color: transparent transparent transparent $drilldown-colour-hover;
			}
			&.current > a::after {
				border-color: transparent transparent transparent $drilldown-current-colour;
			}
			&.current > a:hover::after {
				// arrow right hover color
				border-color: transparent transparent transparent $drilldown-colour-hover;
			}
		}

		.js-drilldown-back {
			a::before {
				// arrow left (back) color
				border-color: transparent $topbar-submenu-colour transparent;
			}

			a:hover::before {
				// arrow left (back) hover color
				border-color: transparent $topbar-submenu-colour-hover transparent;
			}
		}
	}
	//.menu .is-active > a {
	//	background: transparent;
	//}
}

.no-js {
	@include breakpoint(small only) {
		.top-bar {
			display: none;
		}
	}
	@include breakpoint(medium) {
		.title-bar {
			display: none;
		}
	}
}

